import { useEffect, useState, useRef } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { ButtonSend, ButtonDelete, InputField, MultipleSelect, SelectField, Switch } from '../../../../components';
import AddContact from './AddContact';
import ContactList from './ContactList';
import RadioMaterialType from './RadioMaterialType';
import { MAILER_URL } from '../../../../config/constants';

const EditCompany = () => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [newSelectedGroupsList, setNewSelectedGroupsList] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [portuguese, setPortuguese] = useState(false);
  const [english, setEnglish] = useState(false);
  const [call, setCall] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [contactEmail, setContactEmail] = useState();
  const [contactName, setContactName] = useState();
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState();
  const [isActive, setIsActive] = useState(null);
  const [materialType, setMaterialType] = useState(null);

  const formRef = useRef(null);

  useEffect(() => {
    if (selectedCompany) setDisabled(false);
    else setDisabled(true);

    if (formRef.current) formRef.current?.reset();
  }, [selectedCompany]);

  const getSelectValue = async (data) => setNewSelectedGroupsList(data);
  const getSelectCompanyValue = async (data) => setSelectedCompany(data);
  const getMaterialTypeValue = async (data) => setMaterialType(data);

  const handleSwitchAtiveChange = (data) => setIsActive(data);

  const getInfoContacts = async (name, email, file) => {
    setContactName(name);
    setContactEmail(email);
    setFile(file);
  };

  useEffect(() => {
    const idCompany = selectedCompany?.id;

    if (!idCompany) return;

    (async () => {
      const options = {
        method: 'POST',
        body: JSON.stringify({ idCompany }),
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      };

      const response = await fetch(`${MAILER_URL}/company/getOne`, options);

      const message = await response.json();

      setEnglish(message.company.is_english);
      setPortuguese(message.company.is_portuguese);
      setSelectedGroups(message.company.groups);
      setCompanyContacts(message.company.contacts);
      setIsActive(message.company.is_active);
      setMaterialType(message.company.must_send_as_attachment ? 'Attachment' : 'Link');

    })();

  }, [selectedCompany]);

  const verifyFields = (form) => {
    const formData = new FormData(form);

    file?.getAll('file').forEach((file) => formData.append('file', new File([file], file.name, { type: file.type })));

    setFormData(formData);

    setCall(!call);

    const groupsList = selectedGroups?.map((item) => item.id_send_group).join(',');
    const newGroupsList = newSelectedGroupsList.map((item) => item.id_send_group).join(',');

    formData.append('groups', newGroupsList || groupsList);
    formData.append('idCompany', selectedCompany?.id);
    formData.append('english', english);
    formData.append('portuguese', portuguese);
    formData.append('name', contactName);
    formData.append('email', contactEmail);
    formData.append('isActive', isActive);
    formData.append('materialType', materialType);

    if (!formData.get('groups')) return;

    for (const [key, value] of formData.entries()) {
      if (key === 'name' || key === 'email') continue;
      if (value === '') return;
    };

    return true;
  };

  const handleSubmit = async () => {
    const options = {
      method: 'PATCH',
      body: formData,
      credentials: 'include'
    };

    const response = await fetch(`${MAILER_URL}/company/update`, options);

    const { error } = await response.json();

    if (response.status !== 200) return error;
  };

  const handleDelete = async () => {
    const options = {
      method: 'DELETE',
      credentials: 'include',
    };

    const response = await fetch(`${MAILER_URL}/company/delete/${selectedCompany.id}`, options);

    if (response.status !== 204) return 'Falha ao deletar a empresa!';

  };

  const handleDownloadContacts = async () => {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    const response = await fetch(`${MAILER_URL}/contact/contactsInfoExcel`, options);

    const blob = await response.blob();

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'contacts.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>

        <legend style={styles.title}> Informações da Empresa
          <Button onClick={handleDownloadContacts} style={styles.buttonDownload}>
            Download
            <DownloadIcon style={{ cursor: 'pointer', margin: '0' }} />
          </Button>
        </legend>

        <Box component='form' ref={formRef} autoComplete='off' onSubmit={handleSubmit} style={styles.contentBox}>
          <Box style={styles.companyNameBox}>

            <SelectField
              getSelectedValue={getSelectCompanyValue}
              nameVariable='company'
              URL={`${MAILER_URL}/company/getAll`}
              isEmpty={true}
              label='Empresa'
            />

            <Box style={styles.divisor}>
              <InputField
                textColor='#3472BD'
                label='Nome da Empresa'
                type='name'
                name='companyName'
                defaultValue={selectedCompany?.name}
                call={call}
                disabled={disabled}
              />
            </Box>
          </Box>

          <Box style={styles.boxCheckboxAndRadio}>

            <Box >
              <p> Esta empresa aceita idiomas em: </p>
              <FormGroup style={styles.checkBox}>
                <FormControlLabel disabled={disabled} control={<Checkbox checked={portuguese} onChange={() => setPortuguese(!portuguese)} />} label='Português' />
                <FormControlLabel disabled={disabled} control={<Checkbox checked={english} onChange={() => setEnglish(!english)} />} label='Inglês' />
              </FormGroup>
            </Box>

            <RadioMaterialType
              onChange={getMaterialTypeValue}
              value={materialType}
              disabled={disabled}
            />

          </Box>

          <MultipleSelect
            disabled={disabled}
            value={selectedGroups}
            getSelectValue={getSelectValue}
            call={call}
            URL={`${MAILER_URL}/group/getAll`}
            reference='send_group'
          />

          <AddContact
            disabled={disabled}
            getInfoContacts={getInfoContacts}
            call={call}
          />

          {typeof isActive === 'boolean' && (
            <Box>
              <span>Esta empresa está: </span>
              <Switch
                handleAction={handleSwitchAtiveChange}
                options={['Ativa', 'Desativada']}
                changeColor
                width={'8em'}
                checked={isActive}
                disabled={disabled}
              />
            </Box>
          )}

          <Box style={styles.buttonBox}>
            <Box style={styles.buttonDelete}>
              <ButtonDelete
                text='Deletar Empresa'
                handleDelete={handleDelete}
                description='Tem certeza de que deseja deletar esta empresa?'
                descriptionSuccess='Empresa deletada com sucesso!'
              />
            </Box>

            <Box style={styles.buttonSubmit}>
              <ButtonSend
                text='Atualizar Empresa'
                verifyFields={verifyFields}
                handleSubmit={handleSubmit}
                description='Tem certeza de que deseja atualizar esta empresa?'
                descriptionSuccess='Empresa atualizada com sucesso!'
              />
            </Box>
          </Box>

        </Box>
      </fieldset>

      {selectedCompany && <ContactList companyContacts={companyContacts} selectedCompany={selectedCompany} />}

    </Box>
  );
};

const styles = {
  container: {
    width: '50%',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
    marginBottom: '20px',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
  },

  contentBox: {
    padding: '3% 4% 0 4%',
  },

  companyNameBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  divisor: {
    marginLeft: '15px',
    width: '100%',
  },

  boxCheckboxAndRadio: {
    margin: '2% 0',
    display: 'flex',
    gap: 80,
  },

  checkBox: {
    display: 'flex',
    flexDirection: 'row',
  },

  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '8%',
    marginBottom: '4%',
  },

  buttonDownload: {
    textTransform: 'none',
    marginLeft: '10px',
    padding: '0',
    width: 'fit-content',
  }

};

export default EditCompany;