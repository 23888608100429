const emailTemplateBox1 = `
<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta charset="UTF-8">
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <meta name="x-apple-disable-message-reformatting">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta content="telephone=no" name="format-detection">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Comfortaa&display=swap" rel="stylesheet">

  <style>
    #outlook a {
      padding: 0;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    /* END OF IMPORTANT */
    body {
      width: 100%;
      height: 100%;
      font-family: Comfortaa, sans-serif;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    .link-personalized>a {
      line-height: 100% !important;
      font-size: 14px !important;
      color: #333333 !important;
    }

    a {
      line-height: 100% !important;
      font-size: 16px !important;
    }

    table td,
    body,
    .es-wrapper {
      padding: 0;
      margin: 0px;
    }

    img {
      display: block;
      border: 0;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0px;
      line-height: 120%;
      font-family: Comfortaa, sans-serif;
    }

    .es-menu td {
      border: 0;
    }

    .es-menu td a img {
      display: inline-block !important;
      vertical-align: middle;
    }

    /*
END CONFIG STYLES
*/
    .es-header {
      background-color: transparent;
      background-repeat: repeat;
      background-position: center top;
    }

    .es-header-body {
      background-color: #edf2fb;
    }

    .es-content-body {
      background-color: #edf2fb;
    }

    .es-content-body,
    .es-content-body ul li,
    .es-content-body ol li {
      color: #333333;
      font-size: 16px;
    }

    .es-footer {
      background-color: transparent;
      background-repeat: repeat;
      background-position: center top;
    }

    .es-footer-body {
      background-color: #edf2fb;
    }

    .footer-agro {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
    }

    /* RESPONSIVE STYLES Please do not delete and edit CSS styles below. If you don't need responsive layout, please delete this section. */
    @media only screen and (max-width: 700px) {

      /* Gmail-specific styles */
      u+.body .gmail-fix {
        display: none !important;
      }

      /* Outlook-specific styles */
      .ExternalClass {
        width: 100%;
      }

      *[class="gmail-fix"] {
        display: none !important;
      }

      .es-adaptive table,
      .es-left,
      .es-right {
        width: 100% !important;
      }

      .es-content table,
      .es-header table,
      .es-footer table,
      .es-content,
      .es-footer,
      .es-header {
        max-width: 700px !important;
        width: 700px !important;
      }

      .es-adapt-td {
        display: block !important;
        width: 100% !important;
      }

      table.es-table-not-adapt,
      .esd-block-html table {
        width: auto !important;
      }

    }


    /* Email Reset CSS */
    body,
    table,
    footer,
    td,
    p,
    a {
      margin: 0 auto !important;
      word-break: break-word;
      /* Default style */
    }

    /* Gmail-specific word-break style */
    u+.body .gmail-fix {
      word-break: break-word !important;
    }

    /* Outlook-specific word-break style */
    .ExternalClass {
      word-break: break-all !important;
    }
  </style>


</head>

<body>

  <table class="es-wrapper-color es-wrapper first-table" width="100%" cellspacing="0" cellpadding="0" align="center">
    <td class="esd-email-paddings" valign="top">

      <table cellpadding="0" cellspacing="0" class="es-header" align="center">
        <td class="esd-stripe first" align="center">
          <table class="es-header-body" align="center" cellpadding="0" cellspacing="0" width="700"
            style="border-radius: 20px 20px 0px 0px; background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_22fda5291d5015a70dd4bd9ba20196304aa67b494a1d67c9ea36ef7797d39dde/images/rectangle_243.png); background-repeat: no-repeat; background-position: left top; background-size: cover;"
            background="https://tlr.stripocdn.email/content/guids/CABINET_22fda5291d5015a70dd4bd9ba20196304aa67b494a1d67c9ea36ef7797d39dde/images/rectangle_243.png">

            <td style="padding: 30px 40px 40px 0px" class="esd-structure" align="left">
              <table cellpadding="0" cellspacing="0" width="100%">

                <td width="700" class=" esd-container-frame" valign="top" align="center">
                  <table cellpadding="0" cellspacing="0" width="100%">

                    <td align="center">
                      <a target="_blank" href="https://agroconsult.com.br">
                        <img src="https://i.imgur.com/2MdYNUk.png" alt="" style="display: block;" height="60">
                      </a>
                    </td>
                  </table>
                </td>
              </table>
            </td>
          </table>
        </td>
      </table>

      <table class="es-content-body es-content" width="700" maxWidth="700" cellspacing="0" cellpadding="0"
        align="center" style="margin: 0px">
        <tbody style="width: 700px;">
          <td style="padding: 15px 20px 20px 15px; width: 700px; border-collapse: collapse; word-break: break-all;" align="left">
            <table cellspacing="0" cellpadding="0" style="border-radius: 10px; width: 100%;">
              <td style="padding: 15px 30px 30px 30px; border-collapse: collapse; word-break: break-all; font-size: 18px;" align="left">
                <p margin="0" style="margin: 0px !important; padding: 0; font-size: 18px;">
                  {content}
                </p>
              </td>
            </table>
          </td>
          <tr>
            {downloadContent}
          </tr>
        </tbody>
      </table>

      <table cellpadding="50" padding="60px" cellspacing="0" class="es-footer esd-footer-popover" align="center">
        <td class="esd-stripe last" align="center">
          <table class="es-footer-body" style="background-color: transparent;" width="700" cellspacing="0"
            cellpadding="0" align="center">
            <td class="esd-structure"
              style="background-color: #edf2fb; border-radius: 0px 0px 15px 15px; padding: 50px 20px 20px 30px"
              bgcolor="#edf2fb" align="center">
              <table class="es-center" cellspacing="0" cellpadding="0" align="center">
                <tr class="esd-container-frame" width="270" align="center">
                  <table width="100%" cellspacing="0" cellpadding="0">
                    <tr class="esd-block-social es-p20t" align="center" style="font-size: 0px;">
                      <table class="footer-agro" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 5px 0 0 0 ">
                            Enviado por Agroconsult
                            Rodovia SC 401, 4756 - 88032005 - Florianópolis, Santa Catarina, Brasil
                          </td>
                        </tr>
                      </table>
                    </tr>
                  </table>
                </tr>
              </table>
            </td>
          </table>
        </td>
      </table>

    </td>
  </table>

</body>

</html>`

export default emailTemplateBox1