import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const RadioLang = ({ onChange, call }) => {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;

  useEffect(() => {
    if (call && !value) setError(true);
  }, [call, value]);

  return (
    <FormControl>
      <FormLabel style={{ color: error ? errorColor : primaryColor }}> Idioma </FormLabel>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        onChange={(_, value) => {
          setValue(value);
          onChange(value);
          setError(false);
        }}
      >
        <FormControlLabel value='portugues' control={<Radio />} label='Português' />
        <FormControlLabel value='ingles' control={<Radio />} label='Inglês' />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioLang;