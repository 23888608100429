import { useState, useEffect } from 'react';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MultipleSelect = ({ getSelectValue, call, URL, nameProps, value, reference, name }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const idItem = `id_${reference}`;
  const nameItem = `nm_${reference}`;

  useEffect(() => {
    if (call && selectedGroups?.length === 0) setError(true);
  }, [call, selectedGroups]);

  useEffect(() => {
    setSelectedGroups(value || []);
  }, [setSelectedGroups, value]);

  useEffect(() => {
    if (!getSelectValue) return;
    getSelectValue(selectedGroups);
  }, [getSelectValue, selectedGroups]);

  useEffect(() => {
    (async () => {
      const options = {
        method: 'GET',
        credentials: 'include',
      };

      const response = await (await fetch(URL, options)).json();
      const groups = response.message;

      setGroups(groups);

    })();
  }, [URL, nameProps]);

  const handleChange = (e) => {

    const id = e.target.value;

    const filteredGroups = id.map(id => groups.find(item => item[idItem] === id));

    setSelectedGroups(filteredGroups);

    if (e.target.value.length === 0) setError(true);
    else setError(false);

  };

  return (
    <Box style={{ width: '100%' }}>
      <FormControl fullWidth >
        <InputLabel
          size='small'
          id='demo-multiple-chip-label'
          sx={{
            color: error ? errorColor : primaryColor,
            '&.Mui-focused': {
              color: primaryColor,
            },
          }}
        >
          Selecione o(s) Grupo(s) de Envio
        </InputLabel>
        <Select
          name={name}
          sx={{
            borderRadius: '10px',
            color: primaryColor,
            backgroundColor: ' rgb(52, 114, 189, 0.1)',
            '&.MuiInputBase-formControl': {
              width: '100%',
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryColor,
                border: '2px solid rgb(52, 114, 189, 0.7)',
              },
              '&:hover fieldset': {
                borderColor: error ? errorColor : primaryColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: primaryColor,
              },
            },
            '.MuiSvgIcon-root ': {
              fill: '#3472BD !important',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: '20em',
                  color: primaryColor,
                }
              }
            }
          }}
          size='small'
          multiple
          error={error}
          color='primary'
          value={selectedGroups?.map(item => item[idItem]) || []}
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' label='Selecione o(s) Grupo(s) de Envio' />}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5em' }}>
              {selectedGroups?.map((item, index) => (
                <Chip key={index} label={item[nameItem]} />
              ))}
            </Box>)}
        >
          {groups?.map((item) => {
            return (
              <MenuItem
                key={item[idItem]}
                value={item[idItem]}
              >
                {item[nameItem]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultipleSelect;