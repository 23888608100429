import { useState, useEffect, Fragment } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, ListItem, Button } from '@mui/material';
import { Modal } from 'antd';
import EditIcon from '@mui/icons-material/Edit';

import { InputField, ButtonSend, ButtonDelete, MultipleSelect } from '../../../../../../components';

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const ButtonEditModal = ({ contactsChecked, selectedCompany }) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [call, setCall] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (contactsChecked.length > 0) setDisabled(false);
    else setDisabled(true);
  }, [contactsChecked]);

  const showModal = () => setOpen(true);

  const verifyFields = (form) => {

    const formData = new FormData(form);

    const checkboxNames = ['portuguese', 'english'];
    contactsChecked.forEach((item) => {
      checkboxNames.forEach((checkboxName) => {
        const checkboxKey = item.id_contact + checkboxName;
        if (!formData.has(checkboxKey)) formData.append(checkboxKey, false);
      });
    });

    const users = {};

    for (const [key, value] of formData.entries()) {
      const initialNumber = key.match(/^\d+/)[0];
    
      if (!users[initialNumber]) users[initialNumber] = {};
    
      if (key.includes('name')) users[initialNumber].name = value;
      if (key.includes('email')) users[initialNumber].email = value;
      if (key.includes('portuguese')) users[initialNumber].portuguese = value === 'on';
      if (key.includes('english')) users[initialNumber].english = value === 'on';
      if (key.includes('groups')) users[initialNumber].groups = value;

    }

    setData({users, idCompany: selectedCompany.id });

    setCall(!call);

    for (const [, value] of formData.entries()) if (value === '' || value === '<p><br></p>') return;

    return true;

  }

  const handleSubmit = async () => {

    const options = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    }

    const response = await fetch(`${MAILER_URL}/contact/update`, options);

    const { error } = await response.json();

    if (response.status !== 200) return error;

  };

  const handleDelete = async () => {

    const idContact = [];

    contactsChecked.forEach(item => idContact.push(item.id_contact));

    const options = {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        ids: idContact,
        idCompany: selectedCompany.id
      }),
    };

    const response = await fetch(`${MAILER_URL}/contact/delete`, options);

    if (response.status === 204) return;
    else return 'Falha ao deletar o(s) contato(s)!';

  };

  const handleCancel = () => setOpen(false);

  return (
    <Box style={styles.container}>
      <Button color='custom' variant='text' onClick={showModal} style={styles.editButton} disabled={disabled}>
        <EditIcon edge='start' />
        Editar
      </Button>

      <Modal
        width={1000}
        open={open}
        centered
        bodyStyle={{ overflowY: 'auto', marginTop: '2em' }}
        title='Editar Contatos'
        color='#283885'
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[]}
      >
        <Grid sx={{ height: 'fit-content', maxHeight: '60vh' }}>
          <Box component='form'>

            {contactsChecked.map((contact) => (
              <Fragment key={contact.id_contact}>
                <ListItem style={styles.listItem}>

                  <InputField
                    label='Nome'
                    type={'name'}
                    width='38%'
                    defaultValue={contact.nm_contact}
                    name={contact.id_contact + 'name'}
                  />

                  <InputField
                    label='E-mail' 
                    type={'email'}
                    width='60%'
                    defaultValue={contact.de_contact}
                    name={contact.id_contact + 'email'}
                  />

                </ListItem>

                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <Box width={'50%'}>
                    <MultipleSelect
                      name={contact.id_contact + 'groups'}
                      disabled={disabled}
                      value={contact.groups}
                      call={call}
                      URL={`${MAILER_URL}/group/getAll`}
                      reference='send_group'
                    />
                  </Box>

                  <Box style={styles.contactLanguageBox}>
                    <p>Este contato aceita idiomas em: </p>
                    <FormGroup>
                      <FormControlLabel 
                        control={
                          <Checkbox name={contact.id_contact + 'portuguese'} defaultChecked={contact.is_portuguese} />
                        } 
                        label='Português' 
                      />

                      <FormControlLabel 
                        control={
                          <Checkbox name={contact.id_contact + 'english'} defaultChecked={contact.is_english} />
                        } 
                        label='Inglês' 
                      />
                    </FormGroup>
                  </Box>

                </ListItem>

              </Fragment>

            ))}

            <Box style={styles.divisor}>
              <Box style={styles.modalButtonBox}>
                <ButtonDelete
                  text='Deletar'
                  handleDelete={handleDelete}
                  description='Tem certeza de que deseja deletar as informações de contato?'
                  descriptionSuccess='Contato(s) deletado(s) com sucesso!'
                />

                <ButtonSend
                  onClick={handleSubmit}
                  verifyFields={verifyFields}
                  handleSubmit={handleSubmit}
                  text='Editar'
                  description='Deseja editar as informações?'
                  descriptionSuccess='Informações editadas com sucesso!'
                />
              </Box>
            </Box>

          </Box>
        </Grid>

      </Modal>
    </Box>
  );
};

const styles = {

  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    float: 'right',
    width: 'fit-content',
  },

  editButton: {
    fontSize: '17px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
  },

  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
  },

  divisor: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  contactLanguageBox: {
    width: '40%'
  },

  modalButtonBox: {
    marginTop: '3%',
    paddingRight: '16px'
  }

}

export default ButtonEditModal;