import { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';

import SendGroups from './SendGroups';
import WhatsappGroups from './WhatsappGroups';

import { Menu } from '../../../components';

const TabsWrappedLabel = ({ getSelectedTab }) => {
  const [value, setValue] = useState('editwhatsappgroup');

  useEffect(() => {
    getSelectedTab(value)
  }, [getSelectedTab, value]);

  return (
    <Box style={styles.tabContaneir}>
      <Tabs
        value={value}
        onChange={(_, value) => setValue(value)}
        aria-label='wrapped label tabs example'
      >
        <Tab value='editwhatsappgroup' label='Editar Grupos de Whatsapp' />
        <Tab value='editsendgroup' label='Grupos de Envio' />
      </Tabs>
    </Box>
  );
};

const WhatsappConfig = () => {
  const [selectedTab, setSelectedTab] = useState();
  const getSelectedTab = (data) => setSelectedTab(data);

  return (

    <Box style={styles.container}>
      <Menu tabState='' indexSelected='1' />
      <Box style={styles.contentBox}>

        <TabsWrappedLabel getSelectedTab={getSelectedTab} />
        {selectedTab === 'editwhatsappgroup' && <WhatsappGroups />}
        {selectedTab === 'editsendgroup' && <SendGroups />}

      </Box>
    </Box>

  );
};

const styles = {
  tabContaneir: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2em',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minWidth: '100vw',
  },

  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2%'
  },
};

export default WhatsappConfig;