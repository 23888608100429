import Box from '@mui/material/Box';
import EditGroup from './EditGroup';
import CreateGroup from './CreateGroup';

const Groups = () => {

  return (
    <Box style={styles.container}>
      <Box style={styles.contentBox} >
        <CreateGroup />
        <EditGroup />
      </Box>
    </Box>
  );
  
};

const styles = {

  container: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100vw',
  },

};

export default Groups;