import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';

const RadioMaterialType = ({ onChange, disabled, value }) => {

  return (
    <FormControl>
       <p> Esta empresa recebe materiais em: </p>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        onChange={(_, value) => {
          onChange(value);
        }}
      >
        <FormControlLabel checked={value === 'Attachment'} disabled={disabled} value={'Attachment'} control={<Radio />} label='Anexo' />
        <FormControlLabel checked={value === 'Link'} disabled={disabled} value={'Link'} control={<Radio />} label='Link' />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioMaterialType;