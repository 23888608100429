import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MAILER_URL } from '../config/constants';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const login = async (login, password) => {

    setLoading(true);

    const options = {

      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({ login, password }),
      credentials: 'include'

    };

    try {
      
      const response = await fetch(`${MAILER_URL}/login`, options);

      setLoading(false);

      if (response.status === 200) {

        setError(false);
        navigate('/mail');

      } else setError(true);

    } catch (err) {
      console.error(err);
    }

    setLoading(false);

  }

  const logout = async () => {

    const options = {

      method: 'GET',
      credentials: 'include',

    };

    await fetch(`${MAILER_URL}/login/logout`, options);

    navigate('/login');

  }

  return (
    <AuthContext.Provider value={{ login, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;