import { useState } from 'react';
import { Box } from '@mui/material';

import{ InputField, ButtonSend } from '../../../../../components';

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const CreateGroup = () => {

  const [groupName, setGroupName] = useState();
  const [call, setCall] = useState(false);

  const verifyFields = (form) => {
    const data = new FormData(form);

    setCall(!call);

    setGroupName(data.get('group'));

    for (const [, value] of data.entries()) if (value === '') return;

    return true;

  }

  const handleSubmit = async () => {

    const groupData = {
      group: groupName.trim()
    };

    for (let key in groupData) if (!groupData[key]) return;

    const options = {
      method: 'POST',
      body: JSON.stringify(groupData),
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    }

    const response = await fetch(`${ MAILER_URL }/group/create`, options);

    const { error } = await response.json();

    if (response.status !== 201) return error;

  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>

        <legend style={styles.title}>Criar Grupo</legend>

        <Box component='form' style={styles.contentBox}>

          <InputField
            label='Nome do Grupo'
            name='group'
            type='group'
          />

          <Box style={styles.buttonBox}>

            <ButtonSend
              verifyFields={verifyFields}
              value={groupName}
              handleSubmit={handleSubmit}
              text='Criar Grupo'
              description={`Tem certeza de que deseja criar o grupo ${groupName}?`}
              descriptionSuccess={`O grupo ${groupName} foi criado com sucesso!`}
            />

          </Box>

        </Box>
      </fieldset>
    </Box>
  );
}

const styles = {
  container: {
    width: '50%',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '6% 4% 4% 4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  buttonBox: {
    padding: '4% 4% 0 4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    margin: '0 auto',
  },
  

}

export default CreateGroup;